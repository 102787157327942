import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const header_image_mobile = require("../../../assets/img/blogs/setup_blog_header_mob.png");
const header_image = require("../../../assets/img/blogs/setup_blog_header.png");
const section_1 = require("../../../assets/img/blogs/setup_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/setup_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/setup_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/setup_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/setup_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/setup_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/setup_blog_img_7.png");
const section_8 = require("../../../assets/img/blogs/setup_blog_img_8.png");
const section_9 = require("../../../assets/img/blogs/setup_blog_img_9.png");
const section_10 = require("../../../assets/img/blogs/setup_blog_img_10.png");
const section_11 = require("../../../assets/img/blogs/setup_blog_img_11.png");
const section_12 = require("../../../assets/img/blogs/setup_blog_img_12.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });

  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="How to Set Up an MS Teams Ticketing System with Generative AI        "
        description="Learn some easy steps to getting started with your MS Teams ticketing system with the power of Generative AI. Get Workativ and launch today.         "
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt setup_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-section-bold-cta-blog  color-black line-height-18">
                    How to guide: Set up MS Teams Ticketing System with
                    Generative AI
                  </h1>
                ) : (
                  <h1 className="font-page-header-blog-gen-ai color-black">
                    How to guide:
                    <br /> Set up MS Teams
                    <br /> Ticketing System
                    <br /> with Generative AI
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. How to use Workativ to set up a GenAI-based MS Teams
                  ticketing bot?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Why Workativ for your MS Teams-based ticketing system with
                  Generative AI?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Workativ and Generative AI— a powerful combination for MS
                  Teams to enable efficient ticket management
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Benefits of Workativ and Generative AI-based ticketing
                  system inside MS Teams.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Workativ X Generative AI to power MS Teams capabilities for
                  IT ticket management
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft Teams is a powerful and intuitive collaboration
                channel for employees to boost productivity and efficiency. In
                addition to being a collaboration channel, you can optimize{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ms-teams-bot-service-desk-transformation">
                  MS Teams as a Ticketing System.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For your IT help desk needs, MS Teams emerges as a highly
                efficient tool to streamline IT support workflows, help you stay
                organized with help desk tickets, gain productivity, and keep in
                continuous touch with humans.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  {" "}
                  Workativ’s GenAI-based self-service bot
                </a>{" "}
                seamlessly allows you to build an MS Teams-based IT ticketing
                system with the hybrid power of NLU, NLP,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/rag-user-support">
                  RAG,
                </a>{" "}
                and semantic search. This flexible combination enables efficient
                and effective ticket management directly within teams without
                leaving the app or conversations with service desks.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                You can manage and update tickets, address tickets, and provide
                an elevated user experience, all within Teams.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Moreover, MS Teams requires{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  no prior user training or guidance&nbsp;
                </span>
                to use end-to-end IT ticket automation. It substantially
                transforms MS Teams into{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  personalized HR or IT service desks
                </span>{" "}
                by reducing manual intervention and elevating self-service
                capabilities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ gives a straightforward way to bring ticketing
                management processes to MS Teams and build frictionless
                self-service to resolve issues, increase productivity, and build
                collaborative ITSM for the overall growth of service desks. .
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s explore ways to set up an MS Teams-based ticketing system
                with{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Workativ’s intuitive chatbot builder.
                </span>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How to use Workativ to set up a GenAI-based MS Teams ticketing
                bot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ allows you to create multiple conversation flows for
                ticketing systems, regardless of which platform your
                organization uses. These flows can streamline workflows in areas
                such as HR support, IT support, finance, marketing, operations,
                supply chain, etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Here are the steps for you to build your MS Teams-based
                ticketing system with Generative AI —
              </p>
              <h3 className="font-section-sub-header-bold-v2">
                Create Knowledge AI or RAG approach for your MS Teams-based
                ticketing system
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ticketing systems are rigid in finding information from
                knowledge articles or knowledge bases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The emergence of Generative AI or Large Language Models
                increases the speed of information search with accuracy and
                relevancy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As you look to turn your MS Teams into a GenAI-based ticketing
                system, Workativ provides the easiest way to embed LLM search
                from third-party resources via Knowledge AI or RAG integration.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Prepare custom knowledge bases.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It can be anything—
                <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                  HR or IT support use cases
                </a>{" "}
                depending on regular issues or company-specific unique cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if your team needs to handle issues such as blue
                screens, system sound malfunctions, or MS Word sync issues with
                the cloud, LLM knowledge or the world of knowledge from the
                internet can help you with generic answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                But, if your query is unique and company-specific, you must
                provide{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  custom answers and solve the problem.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To resolve internal support issues related to HR or IT support,
                you must create comprehensive knowledge articles. The knowledge
                AI or RAG approach applies neural and semantic search to find
                accurate and precise answers to problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Create knowledge articles on password resets, VPN Settings,
                leave applications, reimbursement policies, HR policies, etc.
                You can also pull your knowledge articles from SharePoint,
                Google Drive, Dropbox, Confluence, and Notion.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Sign up process with Workativ.
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="GenAI-based ticketing system on MS Teams sign-up page"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                1. To start with Workativ, you only need to{" "}
                <a href="https://sit-assistant.workativ.ai/authentication/u/direct-signup">
                  sign up
                </a>{" "}
                with all the required details.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                2. Once you are done, you can log in with your credentials and
                land on a welcome page.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="GenAI-based ticketing system on MS Teams Login page"
              />
              <h3 className="font-section-sub-header-small color-black">
                Choose Knowledge AI
              </h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="GenAI-based ticketing system on MS Teams Login page"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                3. On the Worktiv Assistant platform's home page, you can select
                the Knowledge AI button on the left side panel.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI enables you to create ChatGPT-like chat experiences
                with LLMs built with massive knowledge articles, improve search
                results, and solve problems.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Upload knowledge bases from third-party.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                4. As you click Knowledge AI, you are directed to add a
                connection for{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  external KB.
                </span>{" "}
                If your knowledge articles are within SharePoint, Dropbox, or
                Google Drive, you can add the connection. Name it and upload it.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Train bot
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                5. After the upload, it is time to train your bot with
                knowledge. All the articles in your repository can be used to
                train your Knowledge AI bot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Note that the higher the quality of knowledge articles, the more
                accurate the answers for your employees will be and the less
                likely they will have hallucinations.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Create dialogs
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="GenAI-based ticketing system on MS Teams training setup"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                6. Select ‘Dialog’ from the drop-down menu beside External KB.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Depending on the knowledge articles you upload, you can create
                dialogs and provide answers for custom or shared queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The dialog section allows you to create simple FAQs or
                multi-step conversational flows for complex issues.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Dialog set up for GenAI-based ticketing system on MS Teams"
              />
              <h3 className="font-section-normal-text-testimonials-medium color-black">
                FAQs
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                7. You can choose FAQs and create your predefined conditions and
                answers. For example, if you want to help your users with
                printer problems, you can create and prepare answers for
                predefined questions.
              </p>
              <h3 className="font-section-normal-text-testimonials-medium color-black">
                Conversational for complex issues
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                8. If you want to answer complex questions, select the
                conversational column and create your dialog flows.
                Conversational flows can be multi-step dialog flows with
                follow-up answers to questions.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Enable the Knowledge AI feature.
              </h3>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="RAG or Knowledge AI implementation for GenAI-based ticketing system on MS Teams Login page 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                9. Integrating the Knowledge AI feature is easy. You can set it
                up by choosing this option from the drop-down menu.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI helps respond to unique queries from third-party
                resources, speeding up MTTR and reducing downtime impacts.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Create a fallback for agent handover.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                10. The fallback dialog helps your bot handle unique scenarios
                not present in Knowledge AI or knowledge bases. This feature
                asks users to rephrase questions or offer help during agent
                handover.
              </p>
              <h3 className="font-section-sub-header-bold-v2">
                Build MS Teams-Ticketing System from the marketplace.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Other than creating a bot from scratch, you can also choose to
                build your bot from the marketplace. It is an easier option to
                get started in no time.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Download from the marketplace.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                1. Go to the Welcome Page.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Marketplace download of GenAI-based ticketing bot for MS Teams"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                2. Select a pre-built bot from the marketplace.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                3. You can select your preferred{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  ITSM platform,
                </span>{" "}
                e.g.,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Freshdesk.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                4. Find pre-built conversations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                5. Choose one conversation card.
              </p>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="create bot flow on GenAI-based ticketing system on MS Teams "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                6. Click download to download the Freshdesk bot.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Choose pre-built conversation templates.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                7. Choose{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Automations
                </span>{" "}
                from the left-hand side panel.
              </p>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="edit conversations for GenAI-based ticketing system on MS Teams"
              />

              <p className="font-section-normal-text-testimonials line-height-18 ">
                8. Select Drafts to find your downloaded conversations.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Create app workflow
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                9. The warning sign indicates that you need to create app
                workflow automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                10. Provide all necessary details and save them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                11. Activate the workflow.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Go back to Dialog
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                12. Select{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Dialog
                </span>{" "}
                to find your Freshdesk bot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                13. Click the dialog tab to find your dialog.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                14. Select{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Create a Ticket
                </span>{" "}
                dialog.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                15. Select downloaded{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  App Workflow.{" "}
                </span>
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Provide inputs for Call Automation.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                16. Fill in details in the input fields.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                17. Click{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Try
                </span>{" "}
                to check the efficacy of your workflow automation by testing the
                conversation with Try.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Please note that all dialog with a warning must be prepared.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Publish your ITSM bot.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                18. Click{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Publish
                </span>{" "}
                to make your Freshdesk bot live.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Deploy in MS Teams
              </h3>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="chatbot deployment channels to deploy GenAI-based ticketing system 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                19. Select a bot chat channel to deploy your bot.
              </p>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="Select MS Teams to deploy GenAI-based ticketing system  "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                20. Select MS Teams to deploy your bot on Microsoft Teams.
              </p>
              <img
                src={section_12}
                className="blog_image_top_bt"
                alt=" Input fields for MS Teams bot deployment for GenAI-based ticketing system 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                21. Provide details in the window to activate your bot on Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is how you can easily embed the power of Generative AI or
                Knowledge AI for your MS Teams-based ticketing system and
                simplify its use for your employees.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is Generative AI for the service desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ provides a simple-to-use, no-code platform that
                seamlessly allows you to integrate with Knowledge AI or large
                language model architecture for RAG search all within your MS
                Teams and helps turn it into a powerful self-service for
                ticketing needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Zero effort is involved in building your GenAI-based ticketing
                mechanisms inside your MS Teams.
              </p>
              <ul className="float-left w-100 blogs_ul_upd Register for FREE Pilot font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    Your teams require no prior training to get started with
                    Generative AI-based ticketing systems.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    MS Teams is a familiar and intuitive platform for your
                    employees to leverage ticket automation.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    Workativ provides 24/7 support to help you with your
                    workflow setup.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workativ and Generative AI— a powerful combination for MS Teams
                to enable efficient ticket management
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ unlocks immense opportunities to apply Generative AI
                features to your workflows and automate help desk requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This means employees can create tickets without asking human
                agents and get real-time help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The robust approach of Workativ expedites service desks with MS
                Teams-based ticketing systems built on GenAI.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Custom answers for enhanced employee support
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                RAG within Workativ conversational AI provides answers to unique
                questions for human agents. Employees can solve their problems
                steadily by connecting with relevant persons or stakeholders and
                eliminating confusion.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Centralized ticketing system for end-to-end service desk
                  support
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                MS Teams has become a powerful ticketing platform that unleashes
                the power of GPT. This helps employees centralize all ticket
                information in one platform, reducing the need to toggle between
                multiple devices or platforms and become aware of ticket status.
                Workativ centralizes ticket information within MS Teams,
                allowing employees to create tickets, check status, connect with
                agents, and solve problems in real-time.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Enhanced knowledge management for collaborative IT support
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                MS Teams can pull information from Knowledge AI or third-party
                knowledge repositories to feed custom needs and solve unique
                challenges. Besides, Workativ and MS Teams apply Knowledge AI to
                enhance knowledge management so that teams can collaborate
                effortlessly, find information, and speed up problem-solving.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Streamlined repetitive tasks
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ’s Knowledge AI elevates the pace of problem resolution
                for repetitive tasks. By applying Knowledge AI search, employees
                can easily handle issues such as password resets, account
                unlocks, and VPN settings and improve productivity.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Higher end-user experience and satisfaction
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Employees and service desk agents collaborate efficiently with
                each other and interact to solve problems quickly. Workativ’s
                Knowledge AI provides AI suggestions to craft new messages,
                offers consolidated responses for employees to find answers
                quickly, and enables agents to swiftly derive answers from
                knowledge repositories and share them with users for instant
                help.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Practical use cases for ESM needs
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ’s Knowledge AI and MS Teams combine to offer use cases
                for enterprise service management. HR support, IT support,
                Finance, Marketing, and Sales can easily utilize
                company-specific KnoweldgeGPT and transform employee support.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Benefits of Workativ and Generative AI-based ticketing system
                inside MS Teams.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                An MS Teams bot built with Workativ’s Generative AI transforms
                service desks. If you can effectively set up the MS Teams
                ticketing system, you can unleash many benefits.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Faster ticket resolution
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Users can create tickets within MS Teams without human
                intervention. Agents can track tickets in MS Teams, triage them,
                allocate them to the right agent, and solve tickets instantly.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Reduced MTTR
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Agents can constantly monitor tickets within MS Teams and take
                instant action to help employees reduce MTTR. Knowledge AI or
                Generative AI can suggest responses or correct steps to mitigate
                issues based on previous actions or history.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Agent efficiency
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI helps triage tickets instantly, removing the manual
                need to allocate tickets, saving agents’ time, and helping them
                address critical issues.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  SLA maintenance
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ’s GenAI-based MS Teams for ticketing system reduces
                MTTR, which ultimately improves SLA and user experience.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Cost efficiency
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ encourages significant bottom-line savings by reducing
                the need for data storage systems, developer costs, and user
                training by allowing users to leverage its no-code platform.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  User experience
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Microsoft Teams is a familiar collaboration channel to improve
                the adoption of self-service easily. Users can interact easily
                and handle tickets for their problems.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Rich insights into data and analytics
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Integrating GenAI with Workativ’s conversational AI platform
                enables service desks to harness rich insights and use them to
                create performance reports.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workativ X Generative AI to power MS Teams capabilities for IT
                ticket management
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ enables end-to-end ticketing management within MS Teams
                for your ITSM platform, allowing you to seamlessly leverage GPT
                or Generative AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ provides a collaborative approach for service desk
                automation within Teams, eliminating the steep learning curve to
                understand ITSM module workings.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, your team needs less time to adapt and can quickly
                leverage the benefits of GenAI and the MS Teams ticketing
                system.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ drives your service desk towards greater efficiency,
                higher user satisfaction, and growth with powerful automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you would like to implement automated ticketing with the
                power of Generative AI within MS Teams, Workativ conversational
                AI is your answer.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Book a demo today.{" "}
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. Can MS Teams deliver the essence of the proper ticketing
                function with Generative AI features?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft Teams can easily mimic ITSM functioning with the
                powerful integration of{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ conversational AI.
                </a>{" "}
                The MS Teams bot can feature Generative AI features and automate
                end-to-end ITSM workflows within the platform.
              </p>
              <h3 className="font-section-sub-header-small">
                2. Does building your GenAI-based MS Teams bot for a ticketing
                system require a steep learning curve?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ is a no-code platform with an easy-to-use and intuitive
                interface for creating custom ticketing systems for MS Teams.
                Unlike with ServiceNow, SolarWinds, and BMC, you do not need a
                developer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using Workativ’s support videos, you can quickly launch your MS
                Teams bot for service desk use cases.
              </p>
              <h3 className="font-section-sub-header-small">
                3. Can MS Teams deliver powerful Generative AI properties for
                end-to-end automation of ticketing systems?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams has automation capabilities, but more is needed to
                build a dedicated ticketing system with full automation
                capabilities of Generative AI. With the integration with
                Workativ’s conversational AI platform, MS Teams can leverage
                Knowledge AI, apply it to information search, and enhance
                employee search experience in resolving service desk issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees can gain complete flexibility in creating tickets,
                tracking status, following up, and resolving issues faster
                without leaving the MS Teams collaborative channel.
              </p>
              <h3 className="font-section-sub-header-small">
                4. How do service desks use Microsoft Teams as a GenAI-based
                ticketing system?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft Teams can easily pull ITSM features into its platform
                and enable seamless collaboration between employees and agents.
                Users can create tickets, connect with agents, and resolve
                tickets. On the other hand, agents can use Generative AI
                suggestions to craft instant responses, refer to past
                interactions, know actions provided, and capture better insights
                to help users with unique challenges.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. How to use Workativ to set up a GenAI-based MS Teams
                    ticketing bot?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Why Workativ for your MS Teams-based ticketing system
                    with Generative AI?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Workativ and Generative AI— a powerful combination for MS
                    Teams to enable efficient ticket management
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Benefits of Workativ and Generative AI-based ticketing
                    system inside MS Teams.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Workativ X Generative AI to power MS Teams capabilities
                    for IT ticket management
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft Teams is a powerful and intuitive collaboration
                channel for employees to boost productivity and efficiency. In
                addition to being a collaboration channel, you can optimize{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ms-teams-bot-service-desk-transformation">
                  MS Teams as a Ticketing System.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For your IT help desk needs, MS Teams emerges as a highly
                efficient tool to streamline IT support workflows, help you stay
                organized with help desk tickets, gain productivity, and keep in
                continuous touch with humans.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  {" "}
                  Workativ’s GenAI-based self-service bot
                </a>{" "}
                seamlessly allows you to build an MS Teams-based IT ticketing
                system with the hybrid power of NLU, NLP,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/rag-user-support">
                  RAG,
                </a>{" "}
                and semantic search. This flexible combination enables efficient
                and effective ticket management directly within teams without
                leaving the app or conversations with service desks.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                You can manage and update tickets, address tickets, and provide
                an elevated user experience, all within Teams.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Moreover, MS Teams requires{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  no prior user training or guidance&nbsp;
                </span>
                to use end-to-end IT ticket automation. It substantially
                transforms MS Teams into{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  personalized HR or IT service desks
                </span>{" "}
                by reducing manual intervention and elevating self-service
                capabilities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ gives a straightforward way to bring ticketing
                management processes to MS Teams and build frictionless
                self-service to resolve issues, increase productivity, and build
                collaborative ITSM for the overall growth of service desks. .
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s explore ways to set up an MS Teams-based ticketing system
                with{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Workativ’s intuitive chatbot builder.
                </span>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How to use Workativ to set up a GenAI-based MS Teams ticketing
                bot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ allows you to create multiple conversation flows for
                ticketing systems, regardless of which platform your
                organization uses. These flows can streamline workflows in areas
                such as HR support, IT support, finance, marketing, operations,
                supply chain, etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Here are the steps for you to build your MS Teams-based
                ticketing system with Generative AI —
              </p>
              <h3 className="font-section-sub-header-bold-v2">
                Create Knowledge AI or RAG approach for your MS Teams-based
                ticketing system
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ticketing systems are rigid in finding information from
                knowledge articles or knowledge bases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The emergence of Generative AI or Large Language Models
                increases the speed of information search with accuracy and
                relevancy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As you look to turn your MS Teams into a GenAI-based ticketing
                system, Workativ provides the easiest way to embed LLM search
                from third-party resources via Knowledge AI or RAG integration.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Prepare custom knowledge bases.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It can be anything—
                <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                  HR or IT support use cases
                </a>{" "}
                depending on regular issues or company-specific unique cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if your team needs to handle issues such as blue
                screens, system sound malfunctions, or MS Word sync issues with
                the cloud, LLM knowledge or the world of knowledge from the
                internet can help you with generic answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                But, if your query is unique and company-specific, you must
                provide{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  custom answers and solve the problem.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To resolve internal support issues related to HR or IT support,
                you must create comprehensive knowledge articles. The knowledge
                AI or RAG approach applies neural and semantic search to find
                accurate and precise answers to problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Create knowledge articles on password resets, VPN Settings,
                leave applications, reimbursement policies, HR policies, etc.
                You can also pull your knowledge articles from SharePoint,
                Google Drive, Dropbox, Confluence, and Notion.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Sign up process with Workativ.
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="GenAI-based ticketing system on MS Teams sign-up page"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                1. To start with Workativ, you only need to{" "}
                <a href="https://sit-assistant.workativ.ai/authentication/u/direct-signup">
                  sign up
                </a>{" "}
                with all the required details.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                2. Once you are done, you can log in with your credentials and
                land on a welcome page.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="GenAI-based ticketing system on MS Teams Login page"
              />
              <h3 className="font-section-sub-header-small color-black">
                Choose Knowledge AI
              </h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="GenAI-based ticketing system on MS Teams Login page"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                3. On the Worktiv Assistant platform's home page, you can select
                the Knowledge AI button on the left side panel.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI enables you to create ChatGPT-like chat experiences
                with LLMs built with massive knowledge articles, improve search
                results, and solve problems.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Upload knowledge bases from third-party.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                4. As you click Knowledge AI, you are directed to add a
                connection for{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  external KB.
                </span>{" "}
                If your knowledge articles are within SharePoint, Dropbox, or
                Google Drive, you can add the connection. Name it and upload it.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Train bot
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                5. After the upload, it is time to train your bot with
                knowledge. All the articles in your repository can be used to
                train your Knowledge AI bot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Note that the higher the quality of knowledge articles, the more
                accurate the answers for your employees will be and the less
                likely they will have hallucinations.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Create dialogs
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="GenAI-based ticketing system on MS Teams training setup"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                6. Select ‘Dialog’ from the drop-down menu beside External KB.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Depending on the knowledge articles you upload, you can create
                dialogs and provide answers for custom or shared queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The dialog section allows you to create simple FAQs or
                multi-step conversational flows for complex issues.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Dialog set up for GenAI-based ticketing system on MS Teams"
              />
              <h3 className="font-section-normal-text-testimonials-medium color-black">
                FAQs
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                7. You can choose FAQs and create your predefined conditions and
                answers. For example, if you want to help your users with
                printer problems, you can create and prepare answers for
                predefined questions.
              </p>
              <h3 className="font-section-normal-text-testimonials-medium color-black">
                Conversational for complex issues
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                8. If you want to answer complex questions, select the
                conversational column and create your dialog flows.
                Conversational flows can be multi-step dialog flows with
                follow-up answers to questions.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Enable the Knowledge AI feature.
              </h3>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="RAG or Knowledge AI implementation for GenAI-based ticketing system on MS Teams Login page 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                9. Integrating the Knowledge AI feature is easy. You can set it
                up by choosing this option from the drop-down menu.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI helps respond to unique queries from third-party
                resources, speeding up MTTR and reducing downtime impacts.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Create a fallback for agent handover.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                10. The fallback dialog helps your bot handle unique scenarios
                not present in Knowledge AI or knowledge bases. This feature
                asks users to rephrase questions or offer help during agent
                handover.
              </p>
              <h3 className="font-section-sub-header-bold-v2">
                Build MS Teams-Ticketing System from the marketplace.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Other than creating a bot from scratch, you can also choose to
                build your bot from the marketplace. It is an easier option to
                get started in no time.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Download from the marketplace.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                1. Go to the Welcome Page.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Marketplace download of GenAI-based ticketing bot for MS Teams"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                2. Select a pre-built bot from the marketplace.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                3. You can select your preferred{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  ITSM platform,
                </span>{" "}
                e.g.,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Freshdesk.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                4. Find pre-built conversations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                5. Choose one conversation card.
              </p>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="create bot flow on GenAI-based ticketing system on MS Teams "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                6. Click download to download the Freshdesk bot.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Choose pre-built conversation templates.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                7. Choose{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Automations
                </span>{" "}
                from the left-hand side panel.
              </p>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="edit conversations for GenAI-based ticketing system on MS Teams"
              />

              <p className="font-section-normal-text-testimonials line-height-18 ">
                8. Select Drafts to find your downloaded conversations.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Create app workflow
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                9. The warning sign indicates that you need to create app
                workflow automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                10. Provide all necessary details and save them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                11. Activate the workflow.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Go back to Dialog
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                12. Select{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Dialog
                </span>{" "}
                to find your Freshdesk bot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                13. Click the dialog tab to find your dialog.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                14. Select{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Create a Ticket
                </span>{" "}
                dialog.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                15. Select downloaded{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  App Workflow.{" "}
                </span>
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Provide inputs for Call Automation.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                16. Fill in details in the input fields.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                17. Click{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Try
                </span>{" "}
                to check the efficacy of your workflow automation by testing the
                conversation with Try.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Please note that all dialog with a warning must be prepared.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Publish your ITSM bot.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                18. Click{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Publish
                </span>{" "}
                to make your Freshdesk bot live.
              </p>
              <h3 className="font-section-sub-header-small color-black">
                Deploy in MS Teams
              </h3>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="chatbot deployment channels to deploy GenAI-based ticketing system 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                19. Select a bot chat channel to deploy your bot.
              </p>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="Select MS Teams to deploy GenAI-based ticketing system  "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                20. Select MS Teams to deploy your bot on Microsoft Teams.
              </p>
              <img
                src={section_12}
                className="blog_image_top_bt"
                alt=" Input fields for MS Teams bot deployment for GenAI-based ticketing system 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                21. Provide details in the window to activate your bot on Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is how you can easily embed the power of Generative AI or
                Knowledge AI for your MS Teams-based ticketing system and
                simplify its use for your employees.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is Generative AI for the service desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ provides a simple-to-use, no-code platform that
                seamlessly allows you to integrate with Knowledge AI or large
                language model architecture for RAG search all within your MS
                Teams and helps turn it into a powerful self-service for
                ticketing needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Zero effort is involved in building your GenAI-based ticketing
                mechanisms inside your MS Teams.
              </p>
              <ul className="float-left w-100 blogs_ul_upd Register for FREE Pilot font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    Your teams require no prior training to get started with
                    Generative AI-based ticketing systems.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    MS Teams is a familiar and intuitive platform for your
                    employees to leverage ticket automation.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>- </span>
                  <span>
                    Workativ provides 24/7 support to help you with your
                    workflow setup.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workativ and Generative AI— a powerful combination for MS Teams
                to enable efficient ticket management
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ unlocks immense opportunities to apply Generative AI
                features to your workflows and automate help desk requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This means employees can create tickets without asking human
                agents and get real-time help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The robust approach of Workativ expedites service desks with MS
                Teams-based ticketing systems built on GenAI.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Custom answers for enhanced employee support
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                RAG within Workativ conversational AI provides answers to unique
                questions for human agents. Employees can solve their problems
                steadily by connecting with relevant persons or stakeholders and
                eliminating confusion.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Centralized ticketing system for end-to-end service desk
                  support
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                MS Teams has become a powerful ticketing platform that unleashes
                the power of GPT. This helps employees centralize all ticket
                information in one platform, reducing the need to toggle between
                multiple devices or platforms and become aware of ticket status.
                Workativ centralizes ticket information within MS Teams,
                allowing employees to create tickets, check status, connect with
                agents, and solve problems in real-time.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Enhanced knowledge management for collaborative IT support
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                MS Teams can pull information from Knowledge AI or third-party
                knowledge repositories to feed custom needs and solve unique
                challenges. Besides, Workativ and MS Teams apply Knowledge AI to
                enhance knowledge management so that teams can collaborate
                effortlessly, find information, and speed up problem-solving.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Streamlined repetitive tasks
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ’s Knowledge AI elevates the pace of problem resolution
                for repetitive tasks. By applying Knowledge AI search, employees
                can easily handle issues such as password resets, account
                unlocks, and VPN settings and improve productivity.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Higher end-user experience and satisfaction
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Employees and service desk agents collaborate efficiently with
                each other and interact to solve problems quickly. Workativ’s
                Knowledge AI provides AI suggestions to craft new messages,
                offers consolidated responses for employees to find answers
                quickly, and enables agents to swiftly derive answers from
                knowledge repositories and share them with users for instant
                help.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Practical use cases for ESM needs
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ’s Knowledge AI and MS Teams combine to offer use cases
                for enterprise service management. HR support, IT support,
                Finance, Marketing, and Sales can easily utilize
                company-specific KnoweldgeGPT and transform employee support.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Benefits of Workativ and Generative AI-based ticketing system
                inside MS Teams.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                An MS Teams bot built with Workativ’s Generative AI transforms
                service desks. If you can effectively set up the MS Teams
                ticketing system, you can unleash many benefits.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Faster ticket resolution
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Users can create tickets within MS Teams without human
                intervention. Agents can track tickets in MS Teams, triage them,
                allocate them to the right agent, and solve tickets instantly.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Reduced MTTR
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Agents can constantly monitor tickets within MS Teams and take
                instant action to help employees reduce MTTR. Knowledge AI or
                Generative AI can suggest responses or correct steps to mitigate
                issues based on previous actions or history.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Agent efficiency
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI helps triage tickets instantly, removing the manual
                need to allocate tickets, saving agents’ time, and helping them
                address critical issues.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  SLA maintenance
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ’s GenAI-based MS Teams for ticketing system reduces
                MTTR, which ultimately improves SLA and user experience.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Cost efficiency
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ encourages significant bottom-line savings by reducing
                the need for data storage systems, developer costs, and user
                training by allowing users to leverage its no-code platform.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  User experience
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Microsoft Teams is a familiar collaboration channel to improve
                the adoption of self-service easily. Users can interact easily
                and handle tickets for their problems.
              </p>
              <ul class="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-2">
                <li class="font-section-normal-text-testimonials ">
                  Rich insights into data and analytics
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Integrating GenAI with Workativ’s conversational AI platform
                enables service desks to harness rich insights and use them to
                create performance reports.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workativ X Generative AI to power MS Teams capabilities for IT
                ticket management
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ enables end-to-end ticketing management within MS Teams
                for your ITSM platform, allowing you to seamlessly leverage GPT
                or Generative AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ provides a collaborative approach for service desk
                automation within Teams, eliminating the steep learning curve to
                understand ITSM module workings.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, your team needs less time to adapt and can quickly
                leverage the benefits of GenAI and the MS Teams ticketing
                system.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ drives your service desk towards greater efficiency,
                higher user satisfaction, and growth with powerful automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you would like to implement automated ticketing with the
                power of Generative AI within MS Teams, Workativ conversational
                AI is your answer.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Book a demo today.{" "}
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. Can MS Teams deliver the essence of the proper ticketing
                function with Generative AI features?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft Teams can easily mimic ITSM functioning with the
                powerful integration of{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ conversational AI.
                </a>{" "}
                The MS Teams bot can feature Generative AI features and automate
                end-to-end ITSM workflows within the platform.
              </p>
              <h3 className="font-section-sub-header-small">
                2. Does building your GenAI-based MS Teams bot for a ticketing
                system require a steep learning curve?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ is a no-code platform with an easy-to-use and intuitive
                interface for creating custom ticketing systems for MS Teams.
                Unlike with ServiceNow, SolarWinds, and BMC, you do not need a
                developer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using Workativ’s support videos, you can quickly launch your MS
                Teams bot for service desk use cases.
              </p>
              <h3 className="font-section-sub-header-small">
                3. Can MS Teams deliver powerful Generative AI properties for
                end-to-end automation of ticketing systems?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                MS Teams has automation capabilities, but more is needed to
                build a dedicated ticketing system with full automation
                capabilities of Generative AI. With the integration with
                Workativ’s conversational AI platform, MS Teams can leverage
                Knowledge AI, apply it to information search, and enhance
                employee search experience in resolving service desk issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees can gain complete flexibility in creating tickets,
                tracking status, following up, and resolving issues faster
                without leaving the MS Teams collaborative channel.
              </p>
              <h3 className="font-section-sub-header-small">
                4. How do service desks use Microsoft Teams as a GenAI-based
                ticketing system?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Microsoft Teams can easily pull ITSM features into its platform
                and enable seamless collaboration between employees and agents.
                Users can create tickets, connect with agents, and resolve
                tickets. On the other hand, agents can use Generative AI
                suggestions to craft instant responses, refer to past
                interactions, know actions provided, and capture better insights
                to help users with unique challenges.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai"
              className="font-section-normal-text-testimonials"
            >
              Generative AI Service Desks: Catalysts for Successful Digital
              Transformation Initiatives
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk"
              className="font-section-normal-text-testimonials"
            >
              Reactive to Proactive: Leveraging Generative AI for Smarter
              Employee Service Desks
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/leverage-generative-ai-service-desk-cto"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              The CTO's Secret Weapon: Leveraging Generative AI Service Desks
              for Success
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
